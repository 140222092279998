import {
  Drawer,
  DrawerProps,
  Modal,
  Skeleton,
  Tooltip,
  notification,
} from 'antd';
import {
  CardDesc,
  CardHeader,
  CardListContainer,
  CustomContainer,
  DrawerContentContainer,
  DrawerHeader,
  GoalTitle,
  GoalTitleRight,
  HeaderContainer,
  LoadingContainer,
  PathfinderGoalItem,
  PathfinderGoalList,
  SharedContainer,
  StyledSearch,
} from './styles';
import { Close, KeyboardArrowRight } from '@styled-icons/material';
import { useEffect, useState } from 'react';
import { Textarea } from '../textarea/textarea';
import {
  HeaderTitle,
  SelectorContainer,
  StyledButtonContainer,
  TaskContainer,
} from '../task-library/style';
import { DropdownMenu } from '../dropdown-menu';
import {
  MissionCategoryOptions,
  MissionCategorySubcategoryMapping,
  MissionDetailObjectType,
  Subcategories,
} from '../../types';

import { EmptyIcon } from './Icons';
import { useMutation, useQuery } from '@apollo/client';
import { useApiClient } from '../../context';
import { GET_TEMPLATE_MISSIONS } from '../task-library/graphql';
import { EmptyPlaceHolder } from './EmptyPlaceHolder';
import { TaskLibraryActionItems } from '../action-items/task-library-action-items';
import { AddMissionModal } from './add-mission-modal';
import { GET_MISSION_BY_ID_FOR_LIB } from './graphql';
import { StyledTextArea } from '../mission-drawer/style';
import {
  CHANGE_MISSION_DESCRIPTION,
  CHANGE_MISSION_TITLE,
  EDIT_MISSION,
} from '@crimson-education/core-shared-graphql';
import { ConfirmModal } from '../confirm-modal/confirm-modal';
import { SubmitButton, CancelButton } from '../remove-button/style';

export /* @__PURE__*/ const groupsOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Shared',
    value: 'shared',
  },
  {
    label: 'Custom',
    value: 'custom',
  },
];

export /* @__PURE__*/ const MissionLibrary = ({
  visible,
  setDrawerVisible,
  category,
  subCategory,
  onAddMissionSuccess,
  roadmapId,
  userId,
  tenant,
}: DrawerProps & {
  category?: string;
  subCategory?: string;
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  onAddMissionSuccess: (mission: any) => void;
  roadmapId: string;
  userId: string;
  tenant?: string;
}) => {
  const { roadmapApiClient } = useApiClient();
  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };
  const [missionData, setMissionData] = useState<MissionDetailObjectType>();
  const [keyword, setKeyword] = useState('');
  const [missionSubCategoryOptions, setMissionSubCategoryOptions] = useState<
    { label: string; value: string }[]
  >([]);
  const [searchCategory, setSearchCategory] = useState<string | undefined>(
    category,
  );
  const [searchSubcategory, setSearchSubcategory] = useState<
    string | undefined
  >(subCategory);
  const [searchGroups, setSearchGroups] = useState('all');
  const [searchVariables, setSearchVariables] = useState<any>();
  useEffect(() => {
    const variables: any = {};
    if (searchCategory) {
      variables['categories'] = [searchCategory];
    }
    if (searchSubcategory) {
      variables['subcategories'] = [searchSubcategory];
    }
    if (keyword) {
      variables['keyword'] = keyword;
    }
    if (searchGroups && searchGroups !== 'all') {
      variables['groups'] = [searchGroups];
    }
    setSearchVariables(variables);
  }, [searchCategory, searchSubcategory, keyword, searchGroups]);

  const {
    data,
    loading,
    refetch: refetchTemplateMissions,
  } = useQuery(GET_TEMPLATE_MISSIONS, {
    variables: searchVariables,
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (visible) {
      setSearchCategory(category);
      setSearchSubcategory(subCategory);
      setKeyword('');
      setSearchGroups('all');
    }
  }, [visible]);
  const { refetch: refetchMission } = useQuery(GET_MISSION_BY_ID_FOR_LIB, {
    client: roadmapApiClient,
    skip: true,
  });

  const [editMission] = useMutation(EDIT_MISSION, {
    client: roadmapApiClient,
  });

  const [addMissionOpen, setAddMissionOpen] = useState(false);

  const [changeMissionTitle] = useMutation(CHANGE_MISSION_TITLE, {
    client: roadmapApiClient,
    ignoreResults: true,
  });
  const [changeMissionDescription] = useMutation(CHANGE_MISSION_DESCRIPTION, {
    ignoreResults: true,
    client: roadmapApiClient,
  });
  useEffect(() => {
    if (searchCategory) {
      setMissionSubCategoryOptions(
        MissionCategorySubcategoryMapping[
          searchCategory as keyof typeof MissionCategorySubcategoryMapping
        ]
          .filter(
            (x: string | Subcategories) =>
              tenant === 'collegewise' ||
              x !== Subcategories.College_Admissions_101,
          )
          .map((item) => {
            return {
              label: item,
              value: item,
            };
          }),
      );
    }
  }, [searchCategory, tenant]);
  const [checkedItemIds, setCheckedItemIds] = useState<string[]>([]);
  const onCheckActionItem = (id: string, checked: boolean) => {
    setCheckedItemIds((prevCheckedItemIds) =>
      checked
        ? [...prevCheckedItemIds, id]
        : prevCheckedItemIds.filter((itemId) => itemId !== id),
    );
  };
  const onCheckAll = (checked: boolean) => {
    if (checked) {
      if (missionData?.actionItems) {
        setCheckedItemIds(missionData?.actionItems.map((item) => item.id));
      }
    } else {
      setCheckedItemIds([]);
    }
  };
  const [removeConfirmModalOpen, setRemoveConfirmModalOpen] = useState(false);
  const [editMissionModalOpen, setEditMissionModalOpen] = useState(false);
  const editPermitted = !(missionData?.isShared === true);
  const [missionTitle, setMissionTitle] = useState<string | undefined>();
  const [missionDesc, setMissionDesc] = useState<string | undefined>();
  useEffect(() => {
    setMissionTitle(missionData?.title);
    setMissionDesc(missionData?.description);
  }, [missionData]);
  const onRemoveModalConfirm = async () => {
    setRemoveConfirmModalOpen(false);
    const mission = {
      id: missionData?.id,
      action: 'delete',
    };
    await editMission({
      variables: {
        input: {
          userId,
          roadmapId,
          missions: [mission],
        },
      },
    });
    await refetchTemplateMissions();
    setMissionData(undefined);
    notification.success({
      message: 'Mission removed!',
    });
  };
  const onEditMissionModalConfirm = async () => {
    setEditMissionModalOpen(false);
    if (missionData?.title !== missionTitle) {
      await changeMissionTitle({
        variables: {
          input: {
            missionId: missionData?.id,
            title: missionTitle,
          },
        },
      });
    }
    if (missionData?.description !== missionDesc) {
      await changeMissionDescription({
        variables: {
          input: {
            missionId: missionData?.id,
            description: missionDesc,
          },
        },
      });
    }
    await refetchTemplateMissions();
    notification.success({
      message: 'Mission updated!',
    });
  };
  const [missionHasChanged, setMissionHasChanged] = useState(false);
  const [activeMissionId, setActiveMissionId] = useState('');
  return (
    <Drawer
      placement="right"
      visible={visible}
      destroyOnClose
      headerStyle={{
        display: 'none',
      }}
      contentWrapperStyle={{ width: '1128px' }}
      bodyStyle={{ padding: 0 }}
    >
      <DrawerHeader>
        <Close
          style={{
            width: '18px',
            height: '18px',
            color: 'var(--color-stone)',
            cursor: 'pointer',
            position: 'absolute',
            top: '18px',
            right: '18px',
            zIndex: 10,
          }}
          onClick={() => {
            setDrawerVisible(false);
            handleDrawerClose();
          }}
        />
      </DrawerHeader>
      <HeaderContainer>
        <HeaderTitle>Mission Library</HeaderTitle>
        <div
          style={{
            border: '1px solid #E3E7ED',
            boxShadow: '0px 1px 2px rgba(184, 200, 224, 0.2)',
            borderRadius: '30px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '24px',
            background: 'white',
          }}
        >
          <StyledSearch
            bordered={false}
            allowClear
            style={{ width: '277px' }}
            placeholder="Search by keyword"
            onPressEnter={(e: any) => setKeyword(e?.target?.value)}
            onSearch={(value: any) => setKeyword(value)}
          />
        </div>
      </HeaderContainer>
      <DrawerContentContainer>
        <PathfinderGoalList>
          <SelectorContainer>
            <DropdownMenu
              onClickOption={(value: string) => {
                if (value !== searchCategory) {
                  setSearchCategory(value);
                  setSearchSubcategory(undefined);
                }
              }}
              options={MissionCategoryOptions}
              selectedOptionValue={searchCategory}
              placeholder={'Category'}
              selectorStyle={{ width: '144px' }}
            />
            <DropdownMenu
              onClickOption={(value: string) => {
                setSearchSubcategory(value);
              }}
              options={missionSubCategoryOptions}
              selectedOptionValue={searchSubcategory}
              placeholder={'Subcategory'}
              selectorStyle={{ width: '144px' }}
            />
            <DropdownMenu
              onClickOption={(value: string) => {
                setSearchGroups(value);
              }}
              options={groupsOptions}
              selectedOptionValue={searchGroups}
              placeholder=""
            />
          </SelectorContainer>
          {loading ? (
            <LoadingContainer>
              {Array.from({ length: 10 }, (...args) => args[1]).map((item) => (
                <Skeleton.Button
                  key={item}
                  active={true}
                  size="large"
                  shape="default"
                  block={true}
                  style={{
                    height: '48px',
                    border: '1px solid white',
                    marginBottom: '12px',
                  }}
                />
              ))}
            </LoadingContainer>
          ) : (
            <LoadingContainer>
              {data?.getTemplateMissions.length === 0 && (
                <div
                  style={{
                    height: '550px',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <EmptyIcon />
                  <p
                    style={{
                      fontFamily: 'Montserrat',
                      fontWeight: 500,
                      lineHeight: '20px',
                      color: 'var(--color-stone)',
                      marginTop: '40px',
                      maxWidth: 425,
                      textAlign: 'center',
                    }}
                  >
                    No matches found, please try a different word.
                  </p>
                </div>
              )}
              {data?.getTemplateMissions.length !== 0 &&
                data?.getTemplateMissions.map((goal: any) => {
                  return (
                    <PathfinderGoalItem
                      data-role="library-mission-item"
                      active={goal.id === activeMissionId}
                      key={goal.id}
                      onClick={async () => {
                        if (goal.id !== missionData?.id) {
                          if (missionHasChanged) {
                            setEditMissionModalOpen(true);
                            setMissionHasChanged(false);
                          } else {
                            setActiveMissionId(goal.id);
                            const res = await refetchMission({
                              missionId: goal.id,
                            });
                            if (res?.data && res.data.mission) {
                              setMissionData(res.data.mission);
                            }
                          }
                        }
                      }}
                    >
                      <Tooltip title={goal.title} placement="top">
                        <GoalTitle>{goal.title}</GoalTitle>
                      </Tooltip>
                      <GoalTitleRight>
                        {goal.isShared === true ? (
                          <SharedContainer>Shared</SharedContainer>
                        ) : (
                          <CustomContainer>Custom</CustomContainer>
                        )}
                        <KeyboardArrowRight style={{ height: '17px' }} />
                      </GoalTitleRight>
                    </PathfinderGoalItem>
                  );
                })}
            </LoadingContainer>
          )}
        </PathfinderGoalList>
        <CardListContainer>
          {missionData ? (
            <div>
              <CardHeader>
                <StyledTextArea>
                  <Textarea
                    placeholder="Mission Name"
                    value={missionData.title}
                    disabled={!editPermitted}
                    autoSize={{
                      minRows: 1,
                      maxRows: 2,
                    }}
                    onChangeProps={(value) => {
                      setMissionHasChanged(true);
                      setMissionTitle(value);
                    }}
                  />
                </StyledTextArea>
              </CardHeader>
              <CardDesc>
                <StyledTextArea>
                  <Textarea
                    placeholder="Enter mission description here"
                    value={missionData?.description}
                    onChangeProps={async (value) => {
                      setMissionHasChanged(true);
                      setMissionDesc(value);
                    }}
                    autoSize={{
                      minRows: 1,
                      maxRows: 8,
                    }}
                    disabled={!editPermitted}
                  />
                </StyledTextArea>
              </CardDesc>
              <TaskContainer>
                <TaskLibraryActionItems
                  from="mission"
                  actionItemsProps={missionData?.actionItems}
                  onCheckActionItem={onCheckActionItem}
                  onCheckAll={onCheckAll}
                  checkedItemIds={checkedItemIds}
                  editPermitted={editPermitted}
                  loadActionItems={async () => {
                    const res = await refetchMission({
                      missionId: missionData?.id,
                    });
                    if (res?.data && res.data.mission) {
                      setMissionData({
                        ...res.data.mission,
                        title: missionTitle,
                        description: missionDesc,
                      });
                    }
                  }}
                  mission={missionData}
                />
              </TaskContainer>
              <StyledButtonContainer>
                {editPermitted && (
                  <CancelButton
                    onClick={() => {
                      setRemoveConfirmModalOpen(true);
                    }}
                  >
                    Remove
                  </CancelButton>
                )}
                <SubmitButton
                  data-role="add-mission"
                  onClick={() => {
                    setAddMissionOpen(true);
                  }}
                >
                  Add to My List
                </SubmitButton>
              </StyledButtonContainer>
            </div>
          ) : (
            <section
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              <EmptyPlaceHolder />
              <div
                style={{
                  width: '330px',
                  fontFamily: 'Montserrat',
                  lineHeight: '20px',
                  color: 'var(--color-stone)',
                  marginTop: '40px',
                  textAlign: 'center',
                  marginLeft: '77px',
                }}
              >
                Select a mission from the list on the left to view details, or
                search by keyword from the top bar.
              </div>
            </section>
          )}
        </CardListContainer>
      </DrawerContentContainer>
      <AddMissionModal
        category={category ? category : missionData?.category ? missionData?.category : ''}
        subCategory={subCategory ? subCategory : missionData?.subcategory ? missionData?.subcategory : '' }
        roadmapId={roadmapId}
        userId={userId}
        onAddMissionSuccess={async () => {
          if (missionHasChanged) {
            if (missionData?.title !== missionTitle) {
              await changeMissionTitle({
                variables: {
                  input: {
                    missionId: missionData?.id,
                    title: missionTitle,
                  },
                },
              });
            }
            if (missionData?.description !== missionDesc) {
              await changeMissionDescription({
                variables: {
                  input: {
                    missionId: missionData?.id,
                    description: missionDesc,
                  },
                },
              });
            }
            await refetchTemplateMissions();
            setMissionHasChanged(false);
          }
          onAddMissionSuccess({
            id: missionData?.id,
            category: missionData?.category,
            subcategory: missionData?.subcategory,
            title: missionData?.title
          });
        }}
        addMissionOpen={addMissionOpen}
        closeMissionModal={() => setAddMissionOpen(false)}
        missionTitle={missionTitle}
        missionDesc={missionDesc}
        missionId={missionData?.id}
        tasks={
          missionData?.actionItems
            ? missionData?.actionItems.filter((task) =>
                checkedItemIds.includes(task.id),
              )
            : []
        }
      />
      <Modal
        footer={null}
        width={500}
        open={removeConfirmModalOpen}
        bodyStyle={{ padding: '48px 70px 60px' }}
        onCancel={() => setRemoveConfirmModalOpen(false)}
      >
        <ConfirmModal
          onClose={() => setRemoveConfirmModalOpen(false)}
          modalText={`Are you sure you want to remove the ${missionData?.title} mission?`}
          onConfirm={onRemoveModalConfirm}
          modalDesc="Once removed, it will no longer be available in the mission library."
        />
      </Modal>
      <Modal
        footer={null}
        width={500}
        bodyStyle={{ padding: '48px 70px 60px' }}
        open={editMissionModalOpen}
        onCancel={() => setEditMissionModalOpen(false)}
      >
        <ConfirmModal
          onClose={() => setEditMissionModalOpen(false)}
          modalText={`Are you sure you want to save the updates to the ${missionData?.title} mission in the mission library?`}
          onConfirm={onEditMissionModalConfirm}
          modalDesc=""
        />
      </Modal>
    </Drawer>
  );
};
