import { Add } from '@styled-icons/material';
import { Button, Input, Select } from 'antd';
import styled from 'styled-components';
import { StyledButtonFilled, StyledButtonOutlined } from '../buttons';
import TextArea from 'antd/lib/input/TextArea';
import { DatePicker } from 'antd';
import { HeadlessPopover } from '../headless-antd/popover';
const { RangePicker } = DatePicker;

export const StyledAddTaskButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  background-color: var(--color-indigo, #464ac9);
  width: 118px;
  color: white;
  cursor: pointer;
  position: relative;
  height: 26px;
`;

export const StyledAddButton = styled(Add)`
  font-size: 12px;
  width: 20px;
`;

export const StyledSuggestArea = styled.div`
  margin-top: 16px;
  background: #E3E3FC;
  border-radius: 12px;
  padding: 18px;
`;

export const StyledSuggestIcon = styled.div`
`;

export const StyledSuggestContent = styled.div`

`;

export const StyledSuggestTitle = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 12px;
`;

export const StyledGenerateButton = styled.div`
  text-decoration: underline;
  color:  #464AC9;
  cursor: pointer;
  font-size: 12px;
`;

export const StyledSuggestMission = styled.div`
  color: #73747D;
  margin-top: 13px;
  font-size: 12px;
  background-color: white;
  padding: 24px;
  border: 1px solid #E3E7ED;
  border-radius: 8px;
  box-shadow: 0px 2px 10px 0px #8F8F8F1A inset;
`;

export const StyledSuggestMissionPlaceholder = styled.div`
  color: #73747D;
  margin-top: 3px;
  font-size: 12px;
  margin-left: 30px;
`;
export const StyledConfirmTaskTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #1D1E2B;
  text-align: center;
`;

export const StyledConfirmContent = styled.div`
  margin-top: 32px;
`;

export const StyledConfirmField = styled.div`
  display: inline-block;
  margin-right: 8px;
  font-size: 14px;
  font-weight: 600;
`;

export const StyledConfirmContentAnswer = styled.div`
  display: inline-block;
  color: #73747D;
`;
export const StyledConfirmFieldLine = styled.div`
  margin-bottom: 16px;
`;

export const StyledButtonArea = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`
export const CancelButton = styled(StyledButtonOutlined)`
  width: 180px;
  padding: 20px;
`;
export const SubmitButton = styled(StyledButtonFilled)`
  width: 180px;
  padding: 20px;
`;


export const StyledTagAMissionButton = styled(Button)`
  display: flex;
  width: 338px;
  height: 40px;
  padding: 8px 16px;
  color: var(--color-cool-grey);
  font-size: 12px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid var(--Indigo, #e3e7ed);
  background: #fff;
  align-items: center;
  &:hover {
    border: 1px solid var(--Indigo, #464ac9);
  }
`;

export const StyledAddTaskContainer = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  right: 0;
  z-index: 999;
  background: white;
  top: 60px;
  width: 638px;
  box-shadow: 0px 7px 21px 0px #00000040;
`;

export const AddTaskContentContainer = styled.div`
  padding: 14px;
  border: 1px solid #E3E7ED;
  border-radius: 8px;
`;

export const AddTaskTagMissionLine = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`;

export const StyledMissionTitleInput = styled(Input)`
  padding: 0;
  color: var(--color-dark-navy);
  font-weight: 600;
`;

export const StyledCategorySelectContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`

export const StyledCopilotSuggestTitle = styled.div`
  font-weight: 600;
  color: #1D1E2B;
`;

export const StyledExistLabel = styled.div`
  display: inline-block;
  margin-left: 12px;
  width: 70px;
  height: 20px;
  font-size: 11px;
  line-height: 20px;
  text-align: center;
  border-radius: 4px;
  background: linear-gradient(90deg, #3E44A2 0%, rgba(62, 68, 162, 0.6) 100%);
  color: #fff;
`;

export const StyledNewLabel = styled.div`
  display: inline-block;
  margin-left: 12px;
  width: 45px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  gap: 10px;
  border-radius: 4px;
  font-size: 11px;
  background: linear-gradient(90deg, #12C39A 0%, #31E886 100%);
  color: #fff;
`;

export const StyledDisplayMissionTitle = styled.div`
  color: var(--color-dark-navy);
  font-weight: 600;
  font-size: 14px;
`;

export const StyledSelect = styled(Select)`
  & .ant-select-selector {
    padding-left: 0 !important;
  }
  .ant-select-selection-item {
    font-size: 12px;
  }
  .ant-select-selector .ant-select-selection-search {
    left:  0;
  }
`;

export const StyledRichTextContainer = styled.div`
  border-top: 1px solid var(--color-baby-blue);
  .rich-text-editor .rdw-editor-main{
    min-height: auto;
  }
  .public-DraftEditorPlaceholder-root{
    color: var(--color-cool-grey);
  }
  font-weight: 500;
  font-size: 12px;
`;

export const StyledAddNewMissionTitle = styled.div`
  color: #1D1E2B;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
`;

export const StyledAddNewMissionDetail = styled.div`
  margin-top: 28px;
  box-shadow: 0px 2px 10px 0px #8F8F8F1A inset;
  border-radius: 8px;
  border: 1px solid #E3E7ED;
  background: #F4F5FF;
  padding: 12px 20px;
`;

export const StyledAddNewMissionContainer = styled.div`
`;

export const StyledLinearGradientLine = styled.div`
  width: 100%;
  height: 12px;
  background: linear-gradient(90deg, #AA4EE7 0%, #464AC9 99.22%);
`;

export const StyledTaskTitleTextArea = styled(TextArea)`
  font-size: 12px;
  padding: 0;
  font-weight: 700;
  &::placeholder{
    font-weight: 500;
    font-size: 12px;
    color: var(--color-cool-grey);
  }
`;

export const StyledRangePicker = styled(RangePicker)`
  margin-left: 8px;
  .ant-picker-suffix {
    color: #a9acc0;
  }
  padding: 0px !important;
  .ant-picker-input {
    width: 85px;
  }
  .ant-picker-input > input {
    font-size: 12px !important;
  }
  input::placeholder {
    color: #73747d;
  }
  .ant-picker-suffix {
    height: auto;
  }
  .ant-picker-active-bar {
    margin-left: 0px;
  }
`;

export const StyledPopover = styled(HeadlessPopover)`
`;


export const StyledAddFromScratchButton = styled.div<{ canSubmit: boolean }>`
  cursor: ${({ canSubmit }) => (canSubmit ? 'pointer' : 'auto')};
  font-size: 12px;
  width: 44px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  border-radius: 25px;
  background-color: ${({ canSubmit }) => (canSubmit ? '#464AC9' : '#A9ACC0')};
  color: white;
`;

export const StyledLoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
`;

export const StyledNoResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80%;
  color: #babcc5;
`;
