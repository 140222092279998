export enum Categories {
  ACADEMICS = 'ACADEMICS',
  SAT = 'SAT',
  ACTIVITIES = 'ACTIVITIES',
  COMPETITIONS_HONORS = 'COMPETITIONS_HONORS',
  PERSONAL_DEVELOPMENT = 'PERSONAL_DEVELOPMENT',
  CAREERS = 'CAREERS',
  TEST = 'TEST',
  APPLICATIONS = 'APPLICATIONS',
  APPLICATION_COMMON = 'APPLICATION_COMMON',
  APPLICATION_UNIVERSITY = 'APPLICATION_UNIVERSITY',
  APPLICATION_ESSAY = 'APPLICATION_ESSAY',
}
export enum Subcategories {
  School_Academic = 'School Academics',
  University_Programs = 'University Courses & Summer Programs',
  Research = 'Academic Research & Publications',
  Portfolios = 'Creative Portfolios',
  Internship_Experience = 'Internship & Work Experience',
  Extracurricular_Activities = 'Extracurricular Activities',
  Competitions_Honor = 'Competitions Honor',
  Reading = 'Readings',
  Writing = 'Writings',
  Essential = 'Essential Skills & Experiences',
  Relationship = 'Relationship Building',
  College_Admissions_101 = 'College Admissions 101',
  Preparation = 'School Research & Preparation',
  Major = 'Major, Career and Interest Exploration',
  Networking = 'Networking',
  Honor = 'Competitions & Honors',
  Academic_Skill = 'Academic Skills & Essential Experiences',
  Subject_Interest = 'Subject Interest Development',
  NAY = 'UK Non-Application Year (NAY) Curriculum',
  NAY_UK = 'NAY Curriculum/UK Candidacy Building',
  UK_Admissions_Test = 'UK Admissions Test',
  EU_Standardized_Test = 'EU Standardized Test',
  US_High_School_Test = 'US High School Test',
  US_Undergraduate_Test = 'US Undergraduate Test',
  US_Postgraduate_Test = 'US Postgraduate Test',
  English_Proficiency_Test = 'English Proficiency Test',
  US = 'US',
  UK = 'UK',
  EU = 'EU',
  Academic_Enrichment = 'Academic Enrichment',
}


export type ActivityLogTemplate = {
  code: string;
  template: string;
};
export type ActivityLogType = {
  id: string;
  userId: string;
  exist: string;
  update: string;
  createdAt: string;
  template: ActivityLogTemplate;
  values: string;
};

export type MissionDetailObjectType = {
  category?: string;
  description?: string;
  dueDate?: string;
  id: string;
  members?: string[];
  startAt?: string;
  status?: string;
  subcategory?: string;
  title?: string;
  userId: string;
  activityLogs: ActivityLogType[];
  resources: MissionResource[];
  integrationSource?: string;
  linkId?: string;
  actionItems?: ActionItem[];
  isShared?: boolean;
};

export enum MissionResourceType {
  Link = 'Link',
  Video = 'Video',
}

export type MissionResource = {
  title: string;
  type: MissionResourceType;
  url: string;
};

export const missionStatusObject = {
  PLANNED: 'Planned',
  IN_PROGRESS: 'In Progress',
  DONE: 'Completed',
  EXPIRED: 'Expired',
  POSTPONED: 'Postponed',
};

export const missionLegacyStatusObject = {
  PLANNED: 'Planned',
  IN_PROGRESS: 'In Progress',
  DONE: 'Completed',
};

export const MissionCategoryNameMapping = {
  ACADEMICS: 'Academics',
  ACTIVITIES: 'Activities',
  COMPETITIONS_HONORS: 'Honors',
  PERSONAL_DEVELOPMENT: 'Personal Development',
  CAREERS: 'Majors & Careers',
  TEST: 'Testing',
};

export const MissionCategorySubcategoryMapping = {
  ACADEMICS: [
    Subcategories.School_Academic,
    Subcategories.University_Programs,
    Subcategories.Research,
    Subcategories.Portfolios,
  ],
  TEST: ['Testing'],
  ACTIVITIES: [
    Subcategories.Extracurricular_Activities,
    Subcategories.University_Programs,
    Subcategories.Research,
    Subcategories.Internship_Experience,
  ],
  COMPETITIONS_HONORS: [Subcategories.Competitions_Honor],
  PERSONAL_DEVELOPMENT: [
    Subcategories.Reading,
    Subcategories.Writing,
    Subcategories.Essential,
    Subcategories.Relationship,
    Subcategories.NAY,
    Subcategories.Academic_Skill,
    Subcategories.College_Admissions_101,
    Subcategories.Academic_Enrichment,
  ],
  CAREERS: [
    Subcategories.Preparation,
    Subcategories.Major,
    Subcategories.Networking,
  ],
};

export const MissionCategorySubcategoryMappingForTasks = {
  ACADEMICS: [
    Subcategories.School_Academic,
    Subcategories.University_Programs,
    Subcategories.Research,
    Subcategories.Portfolios,
  ],
  TEST: ['Testing', Subcategories.UK_Admissions_Test, Subcategories.EU_Standardized_Test, Subcategories.US_High_School_Test, Subcategories.US_Undergraduate_Test, Subcategories.US_Postgraduate_Test, Subcategories.English_Proficiency_Test],
  ACTIVITIES: [
    Subcategories.Extracurricular_Activities,
    Subcategories.University_Programs,
    Subcategories.Research,
    Subcategories.Internship_Experience,
  ],
  COMPETITIONS_HONORS: [Subcategories.Competitions_Honor],
  PERSONAL_DEVELOPMENT: [
    Subcategories.Reading,
    Subcategories.Writing,
    Subcategories.Essential,
    Subcategories.Relationship,
    Subcategories.NAY,
    Subcategories.Academic_Skill,
    Subcategories.College_Admissions_101,
  ],
  CAREERS: [
    Subcategories.Preparation,
    Subcategories.Major,
    Subcategories.Networking,
  ],
  APPLICATIONS: [
    Subcategories.US,
    Subcategories.UK,
    Subcategories.EU,
  ]
};

export const MissionCategoryOptions = [
  {
    value: 'ACADEMICS',
    label: 'Academics',
  },
  {
    value: 'TEST',
    label: 'Testing',
  },
  {
    value: 'ACTIVITIES',
    label: 'Activities',
  },
  {
    value: 'COMPETITIONS_HONORS',
    label: 'Honors',
  },
  {
    value: 'PERSONAL_DEVELOPMENT',
    label: 'Personal Development',
  },
  {
    value: 'CAREERS',
    label: 'Majors & Careers',
  },
];

export const MissionSubCategoryMapping = {
  'University Courses & Summer Programs': 'program',
  'Academic Research & Publications': 'research',
  'Creative Portfolios': 'project',
};
export enum ActionItemStatus {
  PLANNED = 'PLANNED',
  DONE = 'DONE',
}

export type MissionObjectType = {
  actionItems?: ActionItem[];
  attr?: string;
  badge?: string;
  category?: string;
  description?: string;
  dueDate?: string;
  // experience?: Experience;
  // extracurricular?: ExtracurricularActivity;
  // honor?: Honor;
  id: string;
  integrationSource?: string;
  members?: string[];
  // otherSubcategory?: OtherSubcategories;
  // portfolios?: StudentPortfolios;
  // program?: StudentPrograms;
  // reading?: Reading;
  // research?: StudentResearch;
  // schoolAcademic?: StudentAcademic;
  secondaryCategory?: string;
  startAt?: string;
  status?: string;
  subcategory?: string;
  // subjects?: Subjects[];
  title?: string;
  userId: string;
};
export type ActionItemResource = {
  createdAt: string;
  id: string;
  mediaType?: string;
  orderIndex: number;
  title: string;
  type: string;
  url: string;
  updatedAt: string;
};
export type ActionItem = {
  id: string;
  description: string;
  finishedAt?: string;
  creatorId: string;
  createdAt: string;
  dueDate?: string;
  startAt?: string;
  status?: ActionItemStatus;
  mission?: MissionObjectType;
  type?: string;
  taskGroup?: string;
  attr?: {
    title: string;
  };
  content?: string;
  resources?: ActionItemResource[];
};

export type MissionChangeActions =
  | 'ChangeActionItemStatus'
  | 'DeleteActionItem'
  | 'AddActionItem'
  | 'ChangeMissionStatus';


export const Category2ColorMap = [
  {
    type: Categories.ACADEMICS,
    category: 'Academics',
    color: 'rgba(108, 99, 255, 0.40)',
  },
  {
    type: Categories.TEST,
    category: 'Testing',
    color: 'rgba(63, 140, 255, 0.40)',
  },
  {
    type: Categories.ACTIVITIES,
    category: 'Activity',
    color: 'rgba(237, 75, 83, 0.40)',
  },
  {
    type: Categories.COMPETITIONS_HONORS,
    category: 'Honors',
    color: 'rgba(18, 195, 154, 0.40)',
  },
  {
    type: Categories.PERSONAL_DEVELOPMENT,
    category: 'Personal Development',
    color: 'rgba(253, 170, 2, 0.40)',
  },
  {
    type: Categories.CAREERS,
    category: 'Majors & Careers',
    color: 'rgba(115, 116, 125, 0.40)',
  },
  {
    type: Categories.APPLICATIONS,
    category: 'Applications',
    color: '#CEE07D',
  },
  {
    type: Categories.APPLICATION_COMMON,
    category: 'Applications',
    color: '#CEE07D',
  },
  {
    type: Categories.APPLICATION_UNIVERSITY,
    category: 'Applications',
    color: '#CEE07D',
  },
];

//The subcategory should align with the one in StudentCenter
export enum CollegewisePublicSubcategories {
  // ACADEMICS
  School_Academics = 'School Academics',
  Academic_Research_Publications = 'Academic Research & Publications',
  University_Courses_Summer_Programs = 'University Courses & Summer Programs',
  Creative_Portfolios = 'Creative Portfolios',
  Internship_Work_Experience = 'Internship & Work Experience',

  // ACTIVITIES
  Extracurricular_Activities = 'Extracurricular Activities',
  // University_Courses_Summer_Programs (duplicate)
  // Academic_Research_Publications (duplicate)
  // Internship_Work_Experience (duplicate)

  // CAREERS
  School_Research_Preparation = 'School Research & Preparation',
  Major_Career_Interest_Exploration = 'Major, Career and Interest Exploration',
  // Internship_Work_Experience (duplicate)

  // PERSONAL_DEVELOPMENT
  College_Admissions_101 = 'College Admissions 101',
  Writings = 'Writings',
  Essential_Skills_Experiences = 'Essential Skills & Experiences',

  // COMPETITIONS_HONORS
  Competitions_Honors = 'Competitions & Honors',
}

export enum CrimsonAppPublicSubcategories {
  // ACTIVITIES
  Extracurricular_Activities = 'Extracurricular Activities',
  University_Courses_Summer_Programs = 'University Courses & Summer Programs',
  Academic_Research_Publications = 'Academic Research & Publications',
  Internship_Work_Experience = 'Internship & Work Experience',

  // ACADEMICS
  School_Academics = 'School Academics',
  Creative_Portfolios = 'Creative Portfolios',

  // CAREERS
  School_Research_Preparation = 'School Research & Preparation',
  Major_Career_Interest_Exploration = 'Major, Career and Interest Exploration',
  Networking = 'Networking',

  // PERSONAL_DEVELOPMENT
  Writings = 'Writings',
  Relationship_Building = 'Relationship Building',
  Essential_Skills_Experiences = 'Essential Skills & Experiences',
  Academic_Skills_Essential_Experiences = 'Academic Skills & Essential Experiences',
  UK_Non_Application_Year_Curriculum = 'UK Non-Application Year (NAY) Curriculum',
  NAY_Curriculum = 'NAY Curriculum',

  // COMPETITIONS_HONORS
  Competitions_Honors = 'Competitions & Honors',
}

export const getSubcategoriesForUser = (user: any) => {
  // Filter based on tenant conditions
  if (user?.tenant?.name === 'collegewise') {
    return CollegewisePublicSubcategories;
  }

  return CrimsonAppPublicSubcategories;
};
