import { Drawer, DrawerProps, Select, notification } from "antd"
import { DrawerContentContainer, DrawerHeader, HeaderContainer, StyledSearch } from "../mission-library/styles";
import { Close } from '@styled-icons/material';
import { useEffect, useState } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { Spin } from 'antd';

import {
  MissionDetailObjectType,
  ActionItem as ActionItemType,
  MissionCategoryOptions,
  MissionCategorySubcategoryMapping,
  Categories,
  Subcategories,
} from '../../types';
import { TaskLibraryActionItems } from "../action-items/task-library-action-items";
import { useApiClient } from '../../context';
import { HeaderTitle, SelectorContainer, StyledButtonContainer, TaskContainer, TaskDrawerContentContainer } from "./style";
import { GET_TEMPLATE_ACTION_ITEMS } from "./graphql";
import { AddActionItemModal } from "./add-action-item-modal";
import { ADD_ACTION_ITEMS } from "@crimson-education/core-shared-graphql";
import { DropdownMenu } from "../dropdown-menu";
import { groupsOptions } from "../mission-library/mission-library";
import { CancelButton, SubmitButton } from "../remove-button/style";
import { StyledLoadingContainer } from "../add-task-button/style";

export /* @__PURE__*/ const TaskLibrary = ({ visible, setDrawerVisible, mission, loadActionItems, reloadMission, studentId, from, logger }: DrawerProps & {
  setDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
  mission?: MissionDetailObjectType,
  loadActionItems: () => void;
  reloadMission?: () => void;
  studentId?: string;
  from?: string;
  logger?: any;
}) => {
  const handleDrawerClose = () => {
    setDrawerVisible(false);
  };
  const [keyword, setKeyword] = useState('');
  const [searchCategory, setSearchCategory] = useState<string | undefined>(mission?.category ? mission?.category : from === 'task' ? '' : Categories.ACADEMICS);
  const [searchSubcategory, setSearchSubcategory] = useState<string | undefined>(mission?.subcategory ? mission?.subcategory : from === 'task' ? '' : Subcategories.University_Programs);
  const [addTaskModalOpen, setAddTaskModalOpen] = useState(false);
  const [missionSubCategoryOptions, setMissionSubCategoryOptions] = useState<{ label: string; value: string }[]>([]);
  const { roadmapApiClient } = useApiClient();
  const [addActionItems] = useMutation(ADD_ACTION_ITEMS, {
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
  })
  const [searchGroups, setSearchGroups] = useState('all');
  const [searchVariables, setSearchVariables] = useState<any>();

  useEffect(() => {
    const variables: any = {};
    if (searchCategory) {
      variables['categories'] = [searchCategory];
    }
    if (searchSubcategory) {
      variables['subcategories'] = [searchSubcategory];
    }
    if (keyword) {
      variables['keyword'] = keyword;
    }
    if (searchGroups && searchGroups !== 'all') {
      variables['groups'] = [searchGroups];
    }
    setSearchVariables(variables);
  }, [searchCategory, searchSubcategory, keyword, searchGroups])
  useEffect(() => {
    if (mission) {
      setSearchCategory(mission.category);
      setSearchSubcategory(mission.subcategory)
    }
  }, [mission])

  useEffect(() => {
    if (visible) {
      setSearchCategory(mission?.category ? mission?.category : from === 'task' ? '' : Categories.ACADEMICS);
      setSearchSubcategory(mission?.subcategory ? mission?.subcategory : from === 'task' ? '' : Subcategories.University_Programs);
      setKeyword('');
      setSearchGroups('all');
    }
  }, [visible]);

  const { data, loading } = useQuery<{
    getTemplateActionItems: ActionItemType[];
  }>(GET_TEMPLATE_ACTION_ITEMS, {
    variables: searchVariables,
    client: roadmapApiClient,
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (searchCategory) {
      setMissionSubCategoryOptions(MissionCategorySubcategoryMapping[searchCategory as keyof typeof MissionCategorySubcategoryMapping].map((item) => {
        return {
          label: item,
          value: item,
        }
      }));
    }
  }, [searchCategory])

  const [checkedItemIds, setCheckedItemIds] = useState<string[]>([]);

  const onCheckActionItem = (id: string, checked: boolean) => {
    setCheckedItemIds((prevCheckedItemIds) =>
      checked ? [...prevCheckedItemIds, id] : prevCheckedItemIds.filter((itemId) => itemId !== id)
    );
  }

  const onCheckAll = (checked: boolean) => {
    if (checked) {
      if (data?.getTemplateActionItems) {
        setCheckedItemIds(data?.getTemplateActionItems.map((item) => item.id));
      }
    } else {
      setCheckedItemIds([]);
    }
  }

  const addTasksToMission = async (tasks: ActionItemType[] | undefined, missionId: string) => {
    if (!tasks) {
      return;
    }
    setAddTaskModalOpen(false);
    const input = tasks.map((task) => {
      const inputVariables: any = {
        description: task.description,
        missionId,
        dueDate: task.dueDate,
      }
      if (task.content) {
        inputVariables['content'] = task.content;
      }

      if (task.resources) {
        inputVariables['resources'] = task.resources.map((resource) => {
          return {
            title: resource.title,
            url: resource.url,
            mediaType: resource.mediaType,
            orderIndex: resource.orderIndex,
            type: resource.type,
          }
        });
      }
      return inputVariables;
    });

    await addActionItems({
      variables: {
        input,
      },
    })
    notification.success({
      message: 'Great!',
      description: 'Tasks successfully added to Student Center from the task library!',
    });
    setDrawerVisible(false);
    reloadMission?.();
    loadActionItems();
    if (from && from === 'task') {
      logger?.trackEvent({
        message: 'add_task_selecting_missions_manually_existing',
        metadata: {
          studentId,
          from: 'task_library'
        }
      })
    }
  }

  return <Drawer
    placement="right"
    visible={visible}
    destroyOnClose
    headerStyle={{
      display: 'none',
    }}
    contentWrapperStyle={{ width: '620px' }}
    bodyStyle={{ padding: 0 }}
  >
    <DrawerHeader>
      <Close
        style={{
          width: '18px',
          height: '18px',
          color: 'var(--color-stone)',
          cursor: 'pointer',
          position: 'absolute',
          top: '18px',
          right: '18px',
          zIndex: 10
        }}
        onClick={() => {
          setDrawerVisible(false);
          handleDrawerClose();
        }}
      />
    </DrawerHeader>
    <HeaderContainer>
      <HeaderTitle>
        Task Library
      </HeaderTitle>
      <div
        style={{
          border: '1px solid #E3E7ED',
          boxShadow: '0px 1px 2px rgba(184, 200, 224, 0.2)',
          borderRadius: '30px',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          paddingLeft: '8px',
          background: 'white',
          width: '220px'
        }}
      >
        <StyledSearch
          bordered={false}
          allowClear
          style={{ width: '277px' }}
          placeholder="Search by Keywords"
          onPressEnter={(e: any) => setKeyword(e?.target?.value)}
          onSearch={(value: any) => setKeyword(value)}
        />
      </div>
    </HeaderContainer>
    <TaskDrawerContentContainer>
      <SelectorContainer>
        <DropdownMenu
          onClickOption={(value: string) => {
            if (value !== searchCategory) {
              setSearchCategory(value)
              setSearchSubcategory(undefined);
            }
          }}
          options={MissionCategoryOptions}
          selectedOptionValue={searchCategory}
          placeholder={'Category'}
          selectorStyle={{ width: '144px' }}
        />
        <DropdownMenu
          onClickOption={(value: string) => {
            setSearchSubcategory(value);
          }}
          options={missionSubCategoryOptions}
          selectedOptionValue={searchSubcategory}
          placeholder={'Subcategory'}
          selectorStyle={{ width: '144px' }}
        />
        <DropdownMenu
          onClickOption={(value: string) => {
            setSearchGroups(value);
          }}
          options={groupsOptions}
          selectedOptionValue={searchGroups}
          placeholder=""
        />
      </SelectorContainer>
      <TaskContainer>
        {
          loading ? <StyledLoadingContainer><Spin className="spin-loading" style={{ color: '#464ac9' }} tip="loading..." /></StyledLoadingContainer> :
            <TaskLibraryActionItems actionItemsProps={data?.getTemplateActionItems} onCheckActionItem={onCheckActionItem} onCheckAll={onCheckAll}
              checkedItemIds={checkedItemIds}
            />}
      </TaskContainer>
      <StyledButtonContainer>
        <CancelButton onClick={() => setDrawerVisible(false)}>Cancel</CancelButton>
        <SubmitButton
          disabled={checkedItemIds.length === 0}
          onClick={() => {
            setAddTaskModalOpen(true);
          }}>Add to List</SubmitButton>
      </StyledButtonContainer>
    </TaskDrawerContentContainer>
    <AddActionItemModal
      from={from}
      studentId={studentId}
      mission={mission}
      addTaskModalOpen={addTaskModalOpen}
      closeTaskModal={() => {
        setAddTaskModalOpen(false);
      }}
      tasksFromLib={data?.getTemplateActionItems.filter((task) => checkedItemIds.includes(task.id))}
      confirmAddTaskToMission={(tasks, missionId) => {
        addTasksToMission(tasks, missionId);
      }} />
  </Drawer>
}
